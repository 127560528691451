























































































































































































































































































































































































































































































































































































import Vue from "vue";
import { Messages } from "@/models/enums/messages.enum";
import {
  NewResponseBank,
  RequestCompanyBank,
  RequestMasterBank,
  ResponseBank,
} from "@/models/interface/master.interface";
import { masterServices } from "@/services/master.service";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { ResponsePagination } from "@/models/constant/interface/common.interface";
import { settingsServices } from "@/services/settings.service";
import {
  CustomDataCoa,
  DataMasterCurrency,
  ResponseListOfCoa,
} from "@/models/interface/settings.interface";
import { debounceProcess, debounce } from "@/helpers/debounce";
import {
  currencyFormat,
  numericOnly,
  wordOnly,
} from "@/validator/globalvalidator";
interface DataSourceCompanyBank {
  id: string;
  bankName: string;
  bankBranch: string;
  bankAccNumber: string;
  bankAccName: string;
  currency: string;
  bankAccControlId: string;
  swiftCode: string;
  payables: boolean;
  receivables: boolean;
  country: string;
  active: boolean;
  balance: number | string;
  key: number;
  disableByRow: string[];
  disabledInput: boolean;
  disabledSelect: boolean;
  currencyId: string;
  companyBankType: string;
}
interface Record {
  data: DataSourceCompanyBank;
  index: undefined;
}
interface BankType {
  option: "Bank" | "Cash";
  value: "BANK" | "CASH";
}
export default Vue.extend({
  data() {
    this.getListOfCoa = debounceProcess(this.getListOfCoa, 200);
    this.getListMasterBank = debounceProcess(this.getListMasterBank, 200);
    this.getListMasterBankForCompany = debounceProcess(
      this.getListMasterBankForCompany,
      200
    );
    return {
      dataCompanyBankType: [
        {
          option: "Bank",
          value: "BANK",
        },
        {
          option: "Cash",
          value: "CASH",
        },
      ] as BankType[],
      titleCompanyBank: "" as string,
      dataBank: {} as NewResponseBank,
      dataCoa: {} as ResponseListOfCoa,
      dataCurrency: [] as DataMasterCurrency[],
      mode: "create" as "create" | "update",
      visibleMasterBank: false as boolean,
      visibleCompanyBank: false as boolean,
      loadingTableMaster: false as boolean,
      loadingTableCompany: false as boolean,
      loadingCurrency: false as boolean,
      isPayable: true as boolean,
      isReceivables: true as boolean,
      isActiveMasterBank: true as boolean,
      isActiveCompany: true as boolean,
      page: 1 as number,
      pageBank: 1 as number,
      limit: 10 as number,
      limitBank: 10 as number,
      totalElementsBank: 0 as number,
      totalElements: 0 as number,
      search: "" as string,
      searchCompanyBank: "" as string,
      loadingCreate: false as boolean,
      loadingUpdate: false as boolean,
      loadingCoa: false as boolean,
      loadingMasterBank: false as boolean,
      idBankMaster: "" as string,
      isActive: true as boolean,
      selectedRowKeys: [],
      tabList: [
        {
          key: "bank",
          tab: "Master Bank",
        },
        {
          key: "list",
          tab: "List Of Bank",
        },
      ],
      key: "bank",
      modalOpen: false as boolean,
      isFormSubmitted: false as boolean,
      form: this.$form.createForm(this, { name: "masterBank" }),
      formModalBankMaster: this.$form.createForm(this, {
        name: "masterBankModal",
      }),
      formModalCompanyBank: this.$form.createForm(this, {
        name: "companyBankModal",
      }),
      formRulesCompanyBank: {
        bankName: {
          label: "lbl_bank_name",
          name: "bankName",
          placeholder: "lbl_bank_name_placeholder",
          decorator: [
            "bankName",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        bankBranchName: {
          label: "lbl_bank_branch_name",
          name: "bankBranchName",
          placeholder: "lbl_bank_branch_name_placeholder",
          decorator: [
            "bankBranchName",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        country: {
          label: "lbl_country",
          name: "country",
          placeholder: "lbl_country_placeholder",
          decorator: [
            "country",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        bankAccountNumber: {
          label: "lbl_bank_account_number",
          name: "bankAccountNumber",
          placeholder: "lbl_bank_account_number_placeholder",
          decorator: [
            "bankAccountNumber",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        bankAccountName: {
          label: "lbl_bank_account_name",
          name: "bankAccountName",
          placeholder: "lbl_bank_account_name_placeholder",
          decorator: [
            "bankAccountName",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        currency: {
          label: "lbl_currency",
          name: "currency",
          placeholder: "lbl_currency_placeholder",
          decorator: [
            "currency",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        bankAccountControl: {
          label: "lbl_bank_account_control",
          name: "bankAccountControl",
          placeholder: "lbl_bank_account_control_placeholder",
          decorator: [
            "bankAccountControl",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        swiftCode: {
          label: "lbl_swift_code",
          name: "swiftCode",
          placeholder: "lbl_swift_code_placeholder",
          decorator: ["swiftCode"],
        },
        companyBankType: {
          label: "lbl_type",
          name: "companyBankType",
          placeholder: "lbl_type_placeholder",
          decorator: [
            "companyBankType",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        payables: {
          label: "lbl_payable",
        },
        receivables: {
          label: "lbl_receivable",
        },
        active: {
          label: "lbl_active",
        },
      },
      formRules: {
        name: {
          label: "lbl_bank_name",
          name: "name",
          placeholder: "lbl_name_placeholder",
          decorator: [
            "name",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        code: {
          label: "lbl_bank_code",
          name: "code",
          placeholder: "lbl_code_placeholder",
          decorator: [
            "code",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        country: {
          label: "lbl_country",
          name: "country",
          placeholder: "lbl_country_placeholder",
          decorator: [
            "country",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        active: {
          label: "lbl_active",
          name: "active",
        },
      },
      columnsTableCompany: [
        {
          title: this.$root.$t("lbl_bank_name"),
          dataIndex: "bankName",
          key: "bankName",
          scopedSlots: { customRender: "bankName" },
          responsiveColSelect: [
            {
              name: "bankName",
              placeholder: this.$root.$t("lbl_bank_name_placeholder"),
              style: "width: 100%;",
              disabled: "",
              value: "name",
              options: [] as ResponseBank[],
              loading: false,
            },
            {
              name: "bankAccControlId",
              placeholder: this.$root.$t(
                "lbl_bank_account_control_placeholder"
              ),
              style: "width: 100%;",
              disabled: "",
              value: "id",
              options: [] as CustomDataCoa[],
              loading: false,
            },
          ],
          responsiveColInput: [
            {
              name: "bankBranch",
              placeholder: this.$root.$t("lbl_bank_branch_name_placeholder"),
              style: "width: 100%;",
            },
            {
              name: "bankAccName",
              placeholder: this.$root.$t("lbl_bank_account_name_placeholder"),
              style: "width: 100%;",
            },
            {
              name: "bankAccNumber",
              placeholder: this.$root.$t("lbl_bank_account_number_placeholder"),
              style: "width: 100%;",
            },
            {
              name: "country",
              placeholder: this.$root.$t("lbl_country_placeholder"),
              style: "width: 100%;",
            },
            {
              name: "currency",
              placeholder: this.$root.$t("lbl_currency_placeholder"),
              style: "width: 100%;",
            },
            {
              name: "balance",
              placeholder: this.$root.$t("lbl_balance_placeholder"),
              style: "width: 100%;",
            },
            {
              name: "companyBankType",
              placeholder: this.$root.$t("lbl_type_placeholder"),
              style: "width: 100%;",
            },
          ],
          responsiveColSwitch: [
            {
              name: "payables",
              placeholder: "lbl_payable",
            },
            {
              name: "receivables",
              placeholder: "lbl_receivable",
            },
            {
              name: "active",
              placeholder: "lbl_active",
            },
          ],
        },
        {
          title: this.$root.$t("lbl_bank_branch_name"),
          dataIndex: "bankBranch",
          key: "bankBranch",
          scopedSlots: { customRender: "bankBranch" },
        },
        {
          title: this.$root.$t("lbl_country"),
          dataIndex: "country",
          key: "country",
          scopedSlots: { customRender: "country" },
        },
        {
          title: this.$root.$t("lbl_bank_account_number"),
          dataIndex: "bankAccNumber",
          key: "bankAccNumber",
          scopedSlots: { customRender: "bankAccNumber" },
        },
        {
          title: this.$root.$t("lbl_bank_account_name"),
          dataIndex: "bankAccName",
          key: "bankAccName",
          scopedSlots: { customRender: "bankAccName" },
        },
        {
          title: this.$root.$t("lbl_currency"),
          dataIndex: "currency",
          key: "currency",
          width: 100,
          scopedSlots: { customRender: "currency" },
        },
        {
          title: this.$root.$t("lbl_balance"),
          dataIndex: "balance",
          width: 250,
          key: "balance",
          scopedSlots: { customRender: "balance" },
        },
        {
          title: this.$root.$t("lbl_bank_account_control"),
          dataIndex: "bankAccControlId",
          key: "bankAccControlId",
          scopedSlots: { customRender: "bankAccControlId" },
        },
        {
          title: this.$root.$t("lbl_type"),
          dataIndex: "companyBankType",
          key: "companyBankType",
          scopedSlots: { customRender: "companyBankType" },
        },
        {
          title: this.$root.$t("lbl_payable"),
          dataIndex: "payables",
          key: "payables",
          scopedSlots: { customRender: "payables" },
        },
        {
          title: this.$root.$t("lbl_receivable"),
          dataIndex: "receivables",
          key: "receivables",
          scopedSlots: { customRender: "receivables" },
        },
        {
          title: this.$root.$t("lbl_active"),
          dataIndex: "active",
          key: "active",
          scopedSlots: { customRender: "active" },
        },
        {
          title: this.$root.$t("lbl_action").toString(),
          dataIndex: "operation",
          width: "120px",
          align: "center",
          scopedSlots: { customRender: "operation" },
          fixed: "right",
          button: ["update"],
        },
      ],
      dataSourceCompanyBank: [] as DataSourceCompanyBank[],
      columnsTableMaster: [
        {
          title: this.$root.$t("lbl_bank"),
          dataIndex: "name",
          key: "name",
        },
        {
          title: this.$root.$t("lbl_country"),
          dataIndex: "country",
          key: "country",
        },
        {
          title: this.$root.$t("lbl_active"),
          dataIndex: "active",
          key: "active",
          scopedSlots: { customRender: "isEnable" },
        },
        {
          title: this.$root.$t("lbl_action").toString(),
          dataIndex: "operation",
          width: "120px",
          align: "center",
          scopedSlots: { customRender: "operation" },
          fixed: "right",
          button: ["update"],
        },
      ],
      dataSource: [] as ResponseBank[],
    };
  },
  created() {
    this.getListMasterBank();
    this.getListCompanyBank();
    this.getListOfCoa("");
    this.getCurrency("");
    this.getListMasterBankForCompany("");
  },
  methods: {
    debounce,
    handleValidation(
      event,
      formName: string,
      isModal: boolean,
      masterOrCompany: "master" | "company",
      wordOrNumberOnly: "wordOnly" | "numberOnly"
    ) {
      const value =
        wordOrNumberOnly === "wordOnly"
          ? wordOnly(event.target.value)
          : (numericOnly(event.target.value) as number | string);
      switch (masterOrCompany) {
        case "master":
          // master and not modal
          if (
            event.target.value &&
            !isModal &&
            !this.form.getFieldError(`${formName}`)
          ) {
            this.form.setFieldsValue({
              [formName]: value,
            });
          }
          // master and modal
          else if (
            event.target.value &&
            isModal &&
            !this.formModalBankMaster.getFieldError(`${formName}`)
          ) {
            this.formModalBankMaster.setFieldsValue({
              [formName]: value,
            });
          }
          break;
        case "company":
          // company all modal
          if (event.target.value && !this.form.getFieldError(`${formName}`)) {
            this.formModalCompanyBank.setFieldsValue({
              [formName]: value,
            });
          }
          break;
        default:
          break;
      }
    },
    responseEditTableMaster(response) {
      this.visibleMasterBank = true;
      this.idBankMaster = response.data.id;
      this.isActiveMasterBank = response.data.active;
      debounce(() => {
        this.formModalBankMaster.setFieldsValue({
          name: response.data.name,
          code: response.data.code,
          country: response.data.country,
        });
      }, 500);
    },
    resetFilter() {
      this.search = "";
      this.getListMasterBank();
    },
    resetFilterCompanyBank() {
      this.searchCompanyBank = "";
      this.getListCompanyBank();
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.limit = response.size;
      this.page = 1;
      this.getListCompanyBank();
    },
    responsePageSizeChangeBank(response: ResponsePagination): void {
      this.limitBank = response.size;
      this.pageBank = 1;
      this.getListMasterBank();
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.page = response.page;
      this.getListCompanyBank();
    },
    responseCurrentPageChangeBank(response: ResponsePagination): void {
      this.pageBank = response.page;
      this.getListMasterBank();
    },
    handleAddNew() {
      this.visibleCompanyBank = true;
      this.mode = "create";
      this.formModalCompanyBank.resetFields();
      this.titleCompanyBank = "lbl_add_company_bank";
    },
    reponseEditTable(record: Record) {
      this.visibleCompanyBank = true;
      this.idBankCompanyForUpdate = record.data.id;
      this.isPayable = record.data.payables;
      this.isReceivables = record.data.receivables;
      this.isActiveCompany = record.data.active;
      this.titleCompanyBank = "lbl_edit_company_bank";
      this.mode = "update";
      let params = {
        page: 0,
        limit: 10,
        search: `isParent~false`,
      } as RequestQueryParamsModel;
      if (record.data.bankAccControlId)
        params.search =
          `code~*${record.data.bankAccControlId}*_OR_description~*${record.data.bankAccControlId}*_AND_` +
          params.search;

      // get coa
      this.loadingCoa = true;
      settingsServices
        .listOfCoa(params, "")
        .then(data => {
          this.assignDataCurrency(record.data);
          this.dataCoa = data;
          debounce(() => {
            this.formModalCompanyBank.setFieldsValue({
              bankName: record.data.bankName,
              bankBranchName: record.data.bankBranch,
              country: record.data.country,
              bankAccountNumber: record.data.bankAccNumber,
              bankAccountName: record.data.bankAccName,
              // currency: record.data.currency,
              currency: record.data.currencyId,
              bankAccountControl: data.data[0].id,
              swiftCode: record.data.swiftCode,
              companyBankType: record.data.companyBankType,
            });
          }, 500);
        })
        .finally(() => {
          this.loadingCoa = false;
        });
    },
    handleSwitchTable(checked, key, columnName) {
      this.dataSourceCompanyBank[key] = {
        ...this.dataSourceCompanyBank[key],
        [columnName]: checked,
      };
      this.dataSourceCompanyBank = this.dataSourceCompanyBank.slice();
    },
    handleInput(value, key, _column, columnName) {
      this.dataSourceCompanyBank[key] = {
        ...this.dataSourceCompanyBank[key],
        [columnName]: value,
      };
      this.dataSourceCompanyBank = this.dataSourceCompanyBank.slice();
    },
    handleSelect(value, key, columnName) {
      this.dataSourceCompanyBank[key][columnName] = value;
      this.dataSourceCompanyBank = this.dataSourceCompanyBank.slice();
    },
    handleSearchSelectTable(value, _key, columnName) {
      if (columnName === "bankAccControlId") {
        this.getListOfCoa(value);
      } else if (columnName === "bankName") {
        this.search = value;
        this.getListMasterBank();
      }
    },
    filterDuplicateWithSameId(dataArray) {
      return dataArray.filter(
        (value, index, self) => index === self.findIndex(t => t.id === value.id)
      );
    },

    assignDataCurrency(response) {
      if (response.currencyId) {
        const partial = (
          dataBankNumber: DataMasterCurrency[],
          fieldsUpdate: Partial<DataMasterCurrency>
        ) => {
          return [...dataBankNumber, fieldsUpdate] as DataMasterCurrency[];
        };
        this.dataCurrency = this.filterDuplicateWithSameId(
          partial(this.dataCurrency, {
            id: response.currencyId,
            currencyCode: response.currency,
          })
        );
      }
    },
    getListMasterBankForCompany(value, bankName?) {
      let params = {
        page: this.pageBank - 1,
        limit: this.limitBank,
        sorts: "createdDate:desc",
      } as RequestQueryParamsModel;
      if (value)
        params.search = `name~*${value}*_OR_code~*${value}*_OR_country~*${value}*`;
      if (bankName) params.search = `name~*${bankName}*`;

      this.loadingMasterBank = true;
      masterServices
        .listMasterBank(params)
        .then(data => {
          if (bankName) {
            this.dataBank = {
              ...this.dataBank,
              data: [...this.dataBank.data, ...data.data],
            };
          } else {
            this.dataBank = data;
          }
        })
        .finally(() => {
          this.loadingMasterBank = false;
        });
    },
    getListMasterBank() {
      let params = {
        page: this.pageBank - 1,
        limit: this.limitBank,
      } as RequestQueryParamsModel;
      if (this.search)
        params.search = `name~*${this.search}*_OR_code~*${this.search}*_OR_country~*${this.search}*`;

      this.loadingTableMaster = true;
      this.loadingTableCompany = true;
      masterServices
        .listMasterBank(params)
        .then(data => {
          this.dataSource = data.data.map((dataMap, index) => {
            return { ...dataMap, key: index };
          });
          this.totalElementsBank = data.totalElements;
          this.columnsTableCompany = this.columnsTableCompany.slice();
        })
        .finally(() => {
          this.loadingTableMaster = false;
          this.loadingTableCompany = false;
        });
    },
    getCurrency(valueSearch) {
      const params: RequestQueryParamsModel = {
        page: 0,
        limit: 10,
      };
      if (valueSearch) params.search = `currencyCode~${valueSearch}`;
      this.loadingCurrency = true;
      settingsServices
        .listOfMasterCurrency(params, "")
        .then(response => (this.dataCurrency = response.data))
        .finally(() => (this.loadingCurrency = false));
    },
    getListOfCoa(value, id?) {
      let params = {
        page: 0,
        limit: 10,
        search: `isParent~false_AND_active~true`,
      } as RequestQueryParamsModel;
      if (value)
        params.search = `code~*${value}*_OR_description~*${value}*_AND_isParent~false_AND_active~true`;
      if (id) params.search = `_AND_secureId~*${id}*`;

      // list coa
      this.loadingCoa = true;
      settingsServices
        .listOfCoa(params, "")
        .then(data => {
          if (id) {
            this.dataCoa = {
              ...this.dataCoa,
              data: [...this.dataCoa.data, ...data.data],
            };
          } else {
            this.dataCoa = data;
          }
        })
        .finally(() => {
          this.loadingCoa = false;
        });
    },
    getListCompanyBank() {
      let params = {
        limit: this.limit,
        page: this.page - 1,
      } as RequestQueryParamsModel;
      if (this.searchCompanyBank)
        params.search = `bankAccName~*${this.searchCompanyBank}*_OR_bankAccNumber~*${this.searchCompanyBank}*_OR_bankBranch~*${this.searchCompanyBank}*_OR_bankName~*${this.searchCompanyBank}*_OR_country~*${this.searchCompanyBank}*_OR_currency.currencyCode~*${this.searchCompanyBank}*`;
      this.loadingTableCompany = true;
      masterServices
        .listCompanyBank(params)
        .then(data => {
          let tempData = [] as DataSourceCompanyBank[];
          this.totalElements = data.totalElements;

          data.data.forEach((dataMap, index) => {
            tempData = [
              ...tempData,
              {
                key: index,
                id: dataMap.id,
                bankName: dataMap.bankName,
                bankBranch: dataMap.bankBranch,
                bankAccNumber: dataMap.bankAccNumber,
                bankAccName: dataMap.bankAccName,
                bankAccControlId: dataMap.bankAccControl.description,
                currency: dataMap.currency,
                currencyId: dataMap.currencyId,
                swiftCode: dataMap.swiftCode,
                payables: dataMap.payables,
                receivables: dataMap.receivables,
                country: dataMap.country,
                active: dataMap.active,
                balance: currencyFormat(dataMap.balance),
                disableByRow: ["payables", "receivables", "active"],
                disabledInput: true,
                disabledSelect: true,
                companyBankType: dataMap.companyBankType as string,
              },
            ];
          });
          this.dataSourceCompanyBank = tempData;
        })
        .finally(() => {
          this.loadingTableCompany = false;
        });
    },
    reponseSearchInput(response): void {
      this.search = response ? response : "";
      this.getListMasterBank();
    },
    reponseSearchInputCompanyBank(response): void {
      this.searchCompanyBank = response ? response : "";
      this.getListCompanyBank();
    },
    submitFormCompany() {
      this.formModalCompanyBank.validateFields((err, res) => {
        if (err) return;
        const payload = {
          bankName: res.bankName,
          bankBranch: res.bankBranchName,
          bankAccNumber: res.bankAccountNumber,
          bankAccName: res.bankAccountName,
          currencyId: res.currency,
          bankAccControlId: res.bankAccountControl,
          swiftCode: res.swiftCode ? res.swiftCode : null,
          payables: this.isPayable,
          receivables: this.isReceivables,
          country: res.country,
          active: this.isActiveCompany,
          companyBankType: res.companyBankType,
        } as RequestCompanyBank;
        switch (this.mode) {
          case "create":
            masterServices
              .createCompanyBank(payload)
              .then(() => {
                this.visibleCompanyBank = false;
                this.$notification.success({
                  description: Messages.CREATE_SUCCESS,
                  message: "Success",
                  duration: 30,
                });
                this.getListCompanyBank();
              })
              .catch(() =>
                this.$notification.error({
                  description: Messages.CREATE_FAIL,
                  message: "Error",
                  duration: 30,
                })
              )
              .finally(() => (this.loadingTableCompany = false));
            break;
          case "update":
            this.loadingTableCompany = true;
            masterServices
              .updateCompanyBank(payload, this.idBankCompanyForUpdate)
              .then(() => {
                this.visibleCompanyBank = false;
                this.$notification.success({
                  description: Messages.UPDATE_SUCCESS,
                  message: "Success",
                  duration: 30,
                });
                this.getListCompanyBank();
                this.getListOfCoa("");
              })
              .catch(() =>
                this.$notification.error({
                  description: Messages.UPDATE_FAIL,
                  message: "Error",
                  duration: 30,
                })
              )
              .finally(() => (this.loadingTableCompany = false));
            break;
          default:
            break;
        }
      });
    },
    submitForm(mode): void {
      switch (mode) {
        case "create":
          this.form.validateFields((err, res) => {
            if (err) return;

            const payload = {
              name: res.name,
              code: res.code,
              country: res.country,
              active: this.isActive,
            } as RequestMasterBank;

            this.loadingCreate = true;
            masterServices
              .createMasterBank(payload)
              .then(() => {
                this.$notification.success({
                  description: Messages.CREATE_SUCCESS,
                  message: "Success",
                  duration: 30,
                });
                this.form.resetFields();
                this.idBankMaster = "";
                this.getListMasterBank();
              })
              .catch(() =>
                this.$notification.error({
                  description: Messages.CREATE_FAIL,
                  message: "Error",
                  duration: 30,
                })
              )
              .finally(() => (this.loadingCreate = false));
          });
          break;
        case "update":
          this.formModalBankMaster.validateFields((err, res) => {
            if (err) return;
            if (this.idBankMaster) {
              const payload = {
                name: res.name,
                code: res.code,
                country: res.country,
                active: this.isActiveMasterBank,
              } as RequestMasterBank;

              this.loadingUpdate = true;
              masterServices
                .updateMasterBank(payload, this.idBankMaster)
                .then(() => {
                  this.$notification.success({
                    description: Messages.UPDATE_SUCCESS,
                    message: "Success",
                    duration: 30,
                  });
                  this.form.resetFields();
                  this.idBankMaster = "";
                  this.visibleMasterBank = false;
                  this.getListMasterBank();
                })
                .catch(() =>
                  this.$notification.error({
                    description: Messages.UPDATE_FAIL,
                    message: "Error",
                    duration: 30,
                  })
                )
                .finally(() => (this.loadingUpdate = false));
            } else {
              this.$notification.error({
                message: "Error",
                description: "Please Select 1 Data from Table",
              });
            }
          });
          break;
        default:
          break;
      }
    },
    handleCancelModalCompany(): void {
      if (this.mode === "update") {
        this.getListOfCoa("");
      }
      this.visibleCompanyBank = false;
    },
    handleCancel(): void {
      this.form.resetFields();
      this.idBankMaster = "";
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].componentOptions.children[1].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
});
